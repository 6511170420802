//import Axios from 'axios';
//import LiAuth from '@/api-services/liauth.service'
import Base from "@/api-services/base";
//import Suscripciones from '@/api-services/suscripciones.service'
//const SUSCRIPCIONESUSUARIOS = `${Base.URL}/suscripcionesusuarios`;

export default {
  //Es la suscripción usuario
  SuscripcionesUsuario: null,
  SuscripcionUsuario: null,
  mPerfilID: null,
  /*
   *           0=  Desconocido,

            /// <summary>
            /// Puede hacer todo lo del sistema
            /// </summary>
            1=Administrador,
            /// <summary>
            /// Es jefe o supervisor de personal, este campo se actualiza cuando se asigna
            /// como jefe de departamento, puesto, colaborador, etc
            /// </summary>
            2=Supervisor,
            /// <summary>
            /// Por default todos los trabajadores se les dará este tipo de acceso
            /// </summary>
            3=Colaborador,
            /// <summary>
            /// Usuario de solo consulta, es similar a supervisor
            /// </summary>
           4= Visor,
            /// <summary>
            /// Como supervisor pero además puede ABC de personal
            /// </summary>
            5=Editor*/
  getPerfilID() {
    if (this.mPerfilID != null) return this.mPerfilID;
    var Ls = localStorage.perfilLocal;
    // console.log(Ls);
    if (Ls != null && Ls != "") {
      var PerfilLocal = JSON.parse(Ls);
      if (PerfilLocal.usuario.esSuperAdministrador)
        return (this.mPerfilID = "Administrador");
    }

    this.SuscripcionUsuario = this.getSuscripcionUsuario();
    if (this.SuscripcionUsuario != null)
      this.mPerfilID = this.SuscripcionUsuario.perfilID;
    else this.mPerfilID = "Desconocido";
    return this.mPerfilID;
  },
  PerfilID: "Desconocido",
  //obtiene el SuscripcionID seleccionada
  getSuscripcionID() {
    var SID = localStorage.suscripcionID;
    if (SID == null) {
      // Obtener la primera
      var SuscripcionesUsuario = this.getSuscripcionesUsuario();
      if (SuscripcionesUsuario.length > 0)
        return SuscripcionesUsuario[0].suscripcionID;
    }
    return SID;
  },
  //Asigna la suscripción seleccionada
  setSuscripcionID(SuscripcionID) {
    // console.debug(`value=${SuscripcionID}`);
    localStorage.suscripcionID = SuscripcionID;
  },
  setSuscripcionesUsuario(SuscripcionesUsuario) {
    this.SuscripcionesUsuario = SuscripcionesUsuario;
  },
  getSuscripcionesUsuario() {
    if (this.SuscripcionesUsuario == null) {
      try {
        var Ls = localStorage.perfilLocal;

        if (Ls != null && Ls != "") {
          var PerfilLocal = JSON.parse(Ls);
          //  console.debug(`PerfilLocal=${PerfilLocal}`);
          this.SuscripcionesUsuario = PerfilLocal.usuario.suscripcionesUsuario;
          return this.SuscripcionesUsuario;
        }
      } catch {}
      return [];
    }
    return this.SuscripcionesUsuario;
  },
  //Obtiene una suscripción en particular si no se pasa el suscripcion ID obtendrá la seleccionada
  getSuscripcionUsuario(SuscripcionID) {
    var SuscripcionesUsuario = this.getSuscripcionesUsuario();
    //  console.debug(SuscripcionesUsuario);
    if (SuscripcionID == null) SuscripcionID = this.getSuscripcionID();

    var Ls = localStorage.perfilLocal;
    if (Ls != null && Ls != "") {
      var PerfilLocal = JSON.parse(Ls);
      if (PerfilLocal.usuario.esSuperAdministrador)
        this.PerfilID = "Administrador";
    }

    var SU = SuscripcionesUsuario.find(
      (item) => item.suscripcionID == SuscripcionID
    );
    this.SuscripcionUsuario = SU;
    if (SU != null) this.PerfilID = SU.perfilID;
    return SU;
  },
  SuscripcionMemoria: null,
  getSuscripcion(SuscripcionID) {
    var SU = this.getSuscripcionUsuario(SuscripcionID);
    if (SU == null) {
      if (SuscripcionID == null) SuscripcionID = this.getSuscripcionID();
      var Llave = `SuscripcionID=${SuscripcionID}`;
      SU = this.SuscripcionMemoria;
      if (SU == null || SU.id != this.SuscripcionID) {
        SU = null;
        Base.get(
          null,
          `${Base.URL}/suscripciones/${SuscripcionID}`,
          {},
          (response) => {
            this.SuscripcionMemoria = response.data;
          },
          () => {},
          () => {}
        );
      }

      return SU;
    }
    return SU.suscripcion;
  },
  getColaboradorID(SuscripcionID) {
    var SU = this.getSuscripcionUsuario(SuscripcionID);
    if (SU == null) return null;
    return SU.colaboradorID;
  },
  getPlan(SuscripcionID) {
    var Suscripcion = this.getSuscripcion(SuscripcionID);
    if (Suscripcion == null) return "";
    return Suscripcion.plan;
  },
  getEtiqueta(SuscripcionID) {
    var Suscripcion = this.getSuscripcion(SuscripcionID);
    if (Suscripcion == null) return "";
    return Suscripcion.etiqueta;
  },
  Plan: "",
  getPlan(SuscripcionID) {
    if (SuscripcionID == null && this.Plan != "") {
      return this.Plan;
    }
    var Suscripcion = this.getSuscripcion(SuscripcionID);
    if (Suscripcion == null) return false;
    if (SuscripcionID == null) {
      this.Plan = Suscripcion.plan;
    }
    return Suscripcion.plan;
  },
  /**
   *
   * @param {*} SuscripcionID
   * @param {*} Predeterminado valor predeterminado opciones 'vue','blue','green','orange','red','primary', si no tiene se usará vue
   * @returns
   */
  getColor(SuscripcionID, Predeterminado) {
    if (Predeterminado == null) Predeterminado = "vue";
    var Suscripcion = this.getSuscripcion(SuscripcionID);
    if (Suscripcion == null) return Predeterminado;
    if (Suscripcion.color == null || Suscripcion.color == "")
      return Predeterminado;
    return Suscripcion.color;
  },
  esAdministrador(SuscripcionID) {
    //console.log(this.getPerfilID());
    //! No se esta obteniendo la suscripción del parámetro, no recuerdo porque no se quito, 
    //pero pienso que debería obtenerse
    var PerfilID = this.getPerfilID();
    return PerfilID == "Administrador";
    var SU = this.getSuscripcionUsuario(SuscripcionID);
    if (SU == null) return SU;

    return SU.perfilID == 1;
  },
  esEditor() {
    // console.debug("esEditor");
    return this.getPerfilID() == "Editor";
  },
  esSupervisor() {
    //console.debug("esEditor");
    return this.getPerfilID() == "Supervisor";
  },
  esVisor() {
    //console.debug("esEditor");
    return this.getPerfilID() == "Visor";
  },
  permitirMultiplesAdministradores(SuscripcionID) {
    switch (this.getPlan(SuscripcionID)) {
      //      case "Gratuito":
      //      case "Bronce":
      //      case "Plata":
      case "Oro":
      case "Pruebas":
      case "Platino":
      case "Diamante":
      case "DiamanteElite":
        return true;
      default:
        return false;
        break;
    }
  },
  puedeCrearAdministradores(SuscripcionID) {
    var Ls = localStorage.perfilLocal;
    // console.log(Ls);
    if (Ls != null && Ls != "") {
      var PerfilLocal = JSON.parse(Ls);
      if (PerfilLocal.usuario.esSuperAdministrador)
        return true;
    }

    var SU = this.getSuscripcionUsuario(SuscripcionID);
    if (SU == null) return false;
    if (SU.perfilID != this.ePerfiles[1]) return false;

    return this.permitirMultiplesAdministradores(SuscripcionID);
  },
  puedeCrearClaves(SuscripcionID) {
    switch (this.getPlan(SuscripcionID)) {
      //      case "Gratuito":
      //      case "Bronce":
      case "Plata":
      case "Oro":
      case "Pruebas":
      case "Platino":
      case "Diamante":
      case "DiamanteElite":
        return true;
      default:
        return false;
        break;
    }
  },

  puedeAccesoTotal(SuscripcionID) {
    switch (this.getPlan(SuscripcionID)) {
      //      case "Bronce":
      //case "Plata":
      case "Oro":
      case "Pruebas":
      case "Platino":
      case "Diamante":
      case "DiamanteElite":
        return true;
      default:
        return false;
        break;
    }
  },
  puedeMultiplesDispositivos(SuscripcionID) {
    switch (this.getPlan(SuscripcionID)) {
      //      case "Gratuito":
      //      case "Bronce":
      //case "Plata":
      case "Oro":
      case "Pruebas":
      case "Platino":
      case "Diamante":
      case "DiamanteElite":
        return true;
      default:
        return false;
        break;
    }
  },

  //Obtene los perfiles "Son constantes" definidas en el server;
  ePerfiles: ["Desconocido", "Administrador", "Supervisor", "Colaborador"],

  ObtenEtiquetaPerfil(PerfilID) {
    switch (PerfilID) {
      case "Colaborador":
        return "Auto Servicio";
      case "Visor":
        return "Visor";
      case "Supervisor":
        return "Supervisor";
      case "Editor":
        return "Editor de Personal";
      case "Administrador":
        return "Administrador";
    }
    return "Desconocido";
  },

  /*
   * Obtiene la etiqueta de una suscripción, solo sobre las que tiene acceso el usuario actual
   * */
  ObtenEtiqueta(SuscripcionID) {
    return getEtiqueta(SuscripcionID);
  },
  ObtenSuscripciones(EventoOnSuscripciones) {
    if (this.Suscripciones.length < 1) {
      var Yo = this;
      setTimeout(function () {
        Yo.ObtenSuscripciones(EventoOnSuscripciones);
      }, 1000);
    } else EventoOnSuscripciones(this.Suscripciones);
  },
  ObtenUrlInvitacion(Reunion) {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/#/ri?i=" +
      Reunion.reunionAlternoID
    );
  },
  ObtenUrlEdicion(Reunion) {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/#/usuarios/edicion?i=" +
      Reunion.reunionAlternoID
    );
  },
  /*
  getUsuario(UsuarioID) {
    var R = Axios.get(SUSCRIPCIONESUSUARIOS + `/${UsuarioID}`, LiAuth.header());
    return R;
  },
  get() {
    var R = Axios.get(SUSCRIPCIONESUSUARIOS, LiAuth.header());

    return R;
  },
  postUsuario(data) {
    return Axios.post(SUSCRIPCIONESUSUARIOS, data, LiAuth.header());
  },
  putUsuario(reunionID, data) {
    return Axios.put(`${SUSCRIPCIONESUSUARIOS}/${reunionID}`, data, LiAuth.header());
  },*/
};
